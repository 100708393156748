/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');
@import 'assets/css/common-style.scss';
@import 'assets/css/lsv.scss';
/* Importing Bootstrap SCSS file. */



///****** HOMEPAGE *******////

/* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';
.header-home {
    .header header.site-header  {
        .site-header-box {
            position: absolute;
            //position: relative;
            top: 0;
            left: 0;
            right: 0;
            z-index: 99;
            background: none;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, transparent);
            //background: #fff;
            color: #000;
        }
        .navigation, .logo {
            svg {
                color: #fff;
                //color: #000;
            }
        }
        .header-links-items, .top-menu .top-menu-item-link {
            color: #fff;
            //color: #000;
        }
    }
}
.header-home.scrolled {
    .header header.site-header  {
        .site-header-box {
            position: absolute;
            //position: relative;
            top: 0;
            left: 0;
            right: 0;
            z-index: 99;
            //background: none;
            background: #fff;
            //background: #fff;
            color: #000;
            border-bottom: 1px solid #eeeeee8f;
        }
        .navigation, .logo {
            svg {
                color: #000;
                //color: #000;
            }
        }
        .header-links-items, .top-menu .top-menu-item-link {
            color: #000;
            //color: #000;
        }
    }
}
.header-home2 {
    .header header.site-header  {
        .site-header-box {
            position: absolute;
            //position: relative;
            top: 0;
            left: 0;
            right: 0;
            z-index: 99;
            background: none;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, transparent);
            //background: #fff;
            color: #000;
        }
        .navigation, .logo {
            svg {
                color: #fff;
                //color: #000;
            }
        }
        .header-links-items, .top-menu .top-menu-item-link {
            color: #fff;
            //color: #000;
        }
    }
}

.header-inner {
    background-color: #fff !important;
    .header header.site-header  {
        .navigation, .logo {
            svg {
                color: #000;
            }
        }
        .header-links-items,.top-menu .top-menu-item-link {
            color: #000;
        }
        .site-header-box {
            position: relative;
            box-shadow: 0 0 1px rgb(0 0 0 / 20%);
            color: #000;
            background: #fff !important;
        }
    }
}

.cart-panel.offcanvas {
    width: 425px;
    .checkout-box, .cart .page-title {
        display:none !important;
    }
    .cart {
        .cart-box {
            width: 100%
        }
        .top {
            display: flex !important;
            padding-left: 30px !important;
        }
        .cart-panel-chkout {
            position: sticky;
             bottom: 0;
            display: flex !important;
            border-top: 1px solid;
            border-top-color: #e8e8e1;
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 15px;
            padding-bottom: 20px;
            background-color: #fff;
        }
    }
    .empty-cart {
        height: calc( 100vh - 60px );
        margin-top: -4rem;
        img {
            width: 250px;
        }
        .txt11 {
            font-size: 18px;
            font-weight: 500;
            margin-top: 30px;
        }
    }
}
.lsv-modal.modal-dialog {
    max-width: 100%!important;
    width: 650px;
}
.review-modal.modal-dialog {
    max-width: 100% !important;
    width: 700px;
}
.gallery-backdrop.modal-backdrop {
    background: #fff;
    opacity: 1;
}
.gallery-modal.modal-dialog {
    max-width: unset;
    margin: 0 !important;
    .modal-content {
        height: 100% !important;
    }
}
.size-chart-modal {
    max-width: 600px !important;
    .modal-content {
        background: transparent;
    }
}









@media only screen and (max-width: 480px) and (min-width: 315px)  {
    .header-home.scrolled .header header.site-header .site-header-box {
        background: #fff !important;
    }
    .lsv-modal.modal-dialog {
        margin: 0;
    }
    .otp-input-box {
        .otp-input {
            width: 45px !important;
            font-size: 18px !important;
            height: 45px !important;
        }
    }
    ngb-offcanvas-panel.offcanvas.offcanvas-start, ngb-offcanvas-panel.offcanvas.offcanvas-end {
        max-width: 300px;
    } 
    .other-page .content {
        font-size: 14px;
        padding: 0 20px;
        font-size: 13px;
    }
    .cart-panel.offcanvas {
        width: 425px;
        .checkout-box, .cart .page-title {
            display:none !important;
        }
        .cart {
            .cart-inner {
                .cart-item {
                    .cart-image {
                        flex: 0 0 75px !important;  
                        align-items: flex-start !important;
                        margin-right: 10px !important;
                    }
                }
                .cart-items-container {
                    padding-left: 15px !important;
                    padding-right: 15px !important;
                    padding-top: 5px !important;
                }
            }
            .cart-box {
                width: 100%
            }
            .item-remove svg {
                width: 18px !important;
            }
            .top {
                display: flex !important;
                padding-left: 15px !important;
                padding-right: 15px !important;
                padding-top: 15px !important;
                .heading {
                    font-size: 18px !important;  
                    padding-bottom: 10px !important;
                }
            }
            .cart-item-color {
                display: none !important;
            }
            .cart-panel-chkout {
                position: sticky;
                 bottom: 0;
                display: flex !important;
                border-top: 1px solid;
                border-top-color: #e8e8e1;
                padding-left: 15px;
                padding-right: 15px;
                padding-top: 10px;
                padding-bottom: 15px;
                background-color: #fff;
                z-index: 1;
                .shippinng-text {
                    font-size: 11px !important;
                    margin-bottom: 10px !important;
                }
                .cart-subtotal {
                    font-weight: 500 !important;
                }
            }
            .cart-inner .cart-item .cart-item-details .cart-item-title {
                font-size: 13px !important;
                padding-right: 22px !important;
            }
        }
        .empty-cart {
            height: calc( 100vh - 60px );
            margin-top: -4rem;
            img {
                width: 250px;
            }
            .txt11 {
                font-size: 18px;
                font-weight: 500;
                margin-top: 30px;
            }
        }
    }
}